
///	Colors
$white: #ffffff;
$black: #000000;
$nomad: #b4a99c;
$river-bad: #455560;
$lemon-ginger: #A9B72A;
$alto: #D8D8D8;
$silver: #cccccc;
$mercury: #E3E3E3;
$nandor: #565856;
$limed-spruce: #3c484f;
$stonewall: #938271;
$donkey-brown: #A89480;
$dusty-gray: #9B9B9B;
$schooner: #8f867c;
$elephant: #0B2834;
$eastern-blue: #2486b0;
$tundora: #4A4A4A;
$dusty-gray2: #979797;
$matisse: #1D6B8D;
$light-navy-blue: #335e9a;
$azure: #14b0e6;
$charcoal-grey: #414042;
$azure-two: #14b1e7;
$charcoal-grey-two: #333135;
$cool-grey: #a7a9ac;
$cool-grey-two: #b9babd;
$dusk-blue: #234d87;
$pinkish-red: #ed1c24;
$dark-red: #860101;
$white-two: #d6d6d6;
$duck-egg-blue: #eef9fd;
$purplish-grey: #666566;
$charcoal-grey-three: #3c3a3d;
$pale-grey: #e9edee;
$white-three: #e3e3e3;
$primary-color: #fff719;
$secondary-color: #16315b;
$background-color: #cfd9e2;
$beam-color : #e7ebf0;
$boskalis-grey-color: #ebecf1;
$boskalis-white-color: #ffffff;
$boskalis-blue-color: #cedbe4;
$faux-black: #333;

///	Color assignments
/*$navigation__background: $white;
$header-basket-badge: $lemon-ginger;
$header-input-placeholder: $river-bad;
$header-top-links: $river-bad;
$header-top-hover: $lemon-ginger;
$header-bottom-links: $alto;
$header-bottom-hover: $lemon-ginger;
$nav-backgr: $nomad;
$nav-backgr-hover: $schooner;
$nav-border: $dusty-gray;
$nav-sub-links: $elephant;
$nav-sub-links-cover: $lemon-ginger;
$breadcrumb-links: $river-bad;
$footer-backgr: $river-bad;
$footer-heading: $limed-spruce;
$footer-links-hover: $lemon-ginger;
$footer-bottom: $eastern-blue;
$button-dashed-square: $lemon-ginger;
$info-dashed-round: $stonewall;
$heading-text: $river-bad;
$heading-transparent: $nomad;
$product-title: $river-bad;
$product-title-hover: $lemon-ginger;
$product-price: $donkey-brown;
$select-outer-border: $silver;
$select-inner-border: $alto;
$select-text: $river-bad;
$select-button: $lemon-ginger;
$inputs-border: $alto;
$inputs-text: $tundora;
$product-details-width: $river-bad;
$product-details-price-old: $lemon-ginger;
$product-details-price-new: $tundora;
$product-details-length: $tundora;
$product-details-links: $dusty-gray;
$product-details-links-hover: $lemon-ginger;
$product-details-promo: $eastern-blue;
$product-details-share: $alto;
$a-z-fabrics-text: $elephant;
$border-default: $dusty-gray;
*/

// Container

$layout__max-width: 1180px;
$container-max-widths: (
sm: 480px,
md: 769px,
lg: 1023px,
xl: 1180px
) !default;

// Colors

$primary__color: $black;
$secondary__color: $secondary-color;

$mark-bg: transparent;

$indent__s: 20px;

// Font

$font-size-base: 1.4rem; // Bootstrap

$font-family-name__base: false;
$font-family__base: futura-pt-alt, futura-pt, Arial, sans-serif;
$font-size-ratio__base: 1.2;

// Headings

$h1__font-color: $primary__color;

// Links

$focus__color: $secondary__color;
$active__color: $secondary__color;
$link__color: $primary__color;
$link__hover__color: $secondary__color;
$link__hover__text-decoration: none;
$link__active__text-decoration: none;

// Libs
$slick-font-path: "../fonts/slick/";
$slick-loader-path: "../images/";
$slick-opacity-not-active: 0.5;
$slick-arrow-color: $dusty-gray;

$fa-font-path: "../fonts/fontawesome5";

// Forms

/*
$forms__border: 2px solid $dusty-gray2;
$forms__height: 5.6rem !important;
$forms__margin: .5rem 0 .5rem 0;
$forms__padding: 0 0 0 1rem;
$forms__valign: middle; 
$forms__width: 100%;

$form-field-type-revert: block;
$form-field-type-label-inline__width: 0;

$input-text__border: $forms__border;
$input-text__height: $forms__height;
$input-text__line-height: $forms__height;
$input-text__margin: $forms__margin;
$input-text__padding: $forms__padding;
$input-text__vertical-align: $forms__valign;
$input-text__width: $forms__width;

$select__border: $forms__border;
$select__height: $forms__height;
$select__line-height: $forms__height;
$select__margin: $forms__margin;
$select__padding: $forms__padding;
$select__vertical-align: $forms__valign;
$select__width: $forms__width;

$textarea__border: $forms__border;
$textarea__height: false;
//$textarea__line-height: $forms__height;
$textarea__margin: $forms__margin;
$textarea__padding: $forms__padding;
$textarea__vertical-align: $forms__valign;
$textarea__width: $forms__width;

$form-field-type: block;
$form-field-label__font-weight: normal;
$form-field-type-label-block__align: left;
$form-field-label-asterisk__color: inherit;

//$footer__background-color: ;

$button__min-width: 0;
$button__border-radius: 0;
$button__border: 0;
$button__color: $white;
$button__background: $azure-two;
$button__hover__border: 0;
$button__hover__color: $white;
$button__hover__background: $light-navy-blue;;
$button__active__color: $white;
$button__active__background: $light-navy-blue;
$button-primary__border: 0;
$button-primary__color: $white;
$button-primary__background: $azure-two;
$button-primary__hover__border: 0;
$button-primary__hover__color: $white;
$button-primary__hover__background: $light-navy-blue;
$button-primary__active__color: $white;
$button-primary__active__background: $light-navy-blue;

$link__color: $charcoal-grey;
$link__text-decoration: none;
$link__visited__color: darken($link__color, 20%);
$link__visited__text-decoration: none;
$link__hover__color: darken($link__color, 50%);
$link__hover__text-decoration: none;
$link__active__color: darken($link__color, 20%);
$link__active__text-decoration: none;

$autocomplete__border: 0;
$autocomplete-item__border: 0;


//$submenu__background: rgba(255, 255, 255, 0.5);
$submenu-item__active__border: 0;
$submenu__font-weight: 500;

$modal-title__border: 0;

$breadcrumbs__container-margin: 2rem 0;

$pager__color: $cool-grey;
$pager__line-height: 1em;
$pager-item__padding: 1em;
$pager-current__background: $white-three;
*/

// CATALOG  
/*
$product-item-sku__font-size: 1em;
$product-item-sku__color: $cool-grey-two;
$product-item-name__font-size: 1.15em;
$product-item-name__color: $charcoal-grey;
$product-item-price__font-size: 3rem;
$product-item-price__color: $light-navy-blue;
$product-item-stock__font-size: 0.9em;
$product-item-stock__color: $cool-grey-two;
*/

// FORMS

$form-field-type-revert: block;
$form-field-type-label-inline__width: 0;
$form-field-type: block;

// BOOTSTRAP VARIABLES

$modal-header-border-width: 0;
$modal-content-border-width: 0;
$modal-content-box-shadow-xs: none;
$modal-content-box-shadow-sm-up: none;
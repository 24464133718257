$product-grid-items-per-row-layout-default : 2 !default;

$product-grid-items-per-row-layout-1-screen-s : 3 !default;
$product-grid-items-per-row-layout-1-screen-m : 4 !default;
$product-grid-items-per-row-layout-1-screen-l : 5 !default;

$product-grid-items-per-row-layout-2-left-screen-s : 3 !default;
$product-grid-items-per-row-layout-2-left-screen-m : 4 !default;
$product-grid-items-per-row-layout-2-left-screen-l : '' !default;

$product-grid-items-per-row-layout-2-right-screen-s : 3 !default;
$product-grid-items-per-row-layout-2-right-screen-m : 4 !default;
$product-grid-items-per-row-layout-2-right-screen-l : '' !default;

$product-grid-items-per-row-layout-3-screen-s : 3 !default;
$product-grid-items-per-row-layout-3-screen-m : '' !default;
$product-grid-items-per-row-layout-3-screen-l : '' !default;

$product-grid-items-padding : 0 $indent__base $indent__base !default;
$product-grid-items-margin : 0 0 $indent__s !default;

$product-name-text-decoration : none !default;
$product-name-text-decoration-hover : $link__hover__text-decoration !default;

$toolbar-mode-icon-font-size : 26px !default;
$product-h1-margin-bottom-desktop : $indent__base !default;

@import 'module/listings';
@import 'module/toolbar';
@import 'module/gallery';

//
//  Category view
//  ---------------------------------------------

.cms-boskalis-home-page {
    .back-to-overview-wrap {
        display: none;
    }
}

.modal-popup {
    .modal-inner-wrap {
        max-width: 50%;
        background-color: #fff;
        box-shadow: 0 0 12px 2px rgba(0, 0, 0, 0.35);
        opacity: 1;
        pointer-events: auto;
        border-radius: 10px;
    }
}

.action.primary {
    display: block;
    float: right;
    margin-top: 3rem;
    border: 1px solid #000000;
    border-radius: 9px;
    background-color: #16315b;
    color: #ffffff;
    width: 100%;
    max-width: 15rem;
    font-weight: 600;
    text-transform: uppercase;
    font-size: 1.6rem;
    padding: 0.8rem 1rem 0.8rem 1rem;
}

.action.primary:hover {
    background: #fff719;
    border: 1px solid #fff719;
    color: #16315b;
}

.price-container .price {
    font-size: 2.4rem;
}

.back-to-overview-wrap {
    display: inline-block;
    float: right;
    padding-top: 0.8%;
    cursor: pointer;
    font-size: 1.8rem;

    @include media-breakpoint-down(md) {
        display: none;
    }
}

/*  -----  ALEX  -----  */


.catalog-category-view,
.catalogsearch-result-index {

    .message.info.empty {
        margin-top: 2rem;
    }

    .columns {
        width: 100%;
        position: relative;
        margin-top: 5rem;
    }

    &.page-layout-2columns-left .column.main {
        @include media-breakpoint-up(md) {
            width: 80%;
        }

    }

    .page-title-wrapper {
        display: none;
    }

    .swatch-opt {
        .swatch-attribute {
            margin-bottom: 1rem;

            .swatch-select.size {
                max-width: 200px;
            }
        }

        .swatch-option.text {
            line-height: 1;
        }
    }

    .block-content.filter-content.category-list {
        display: none;

        @include media-breakpoint-up(md) {
            width: 18% !important;
            margin-top: 4.5rem;
            display: block;
        }
    }

    .breadcrumbs-wrapper {
        @include media-breakpoint-up(md) {
            position: absolute;
            top: -5rem;
            left: 0;
            width: 100%;
            padding-left: 1rem;
        }


        .breadcrumbs {
            padding-left: 0;
            display: block;
            text-align: center;

            @include media-breakpoint-up(md) {
                padding-left: 1rem;
                position: absolute;
                top: 2rem;
                left: 0;
                text-align: left;
            }

            @include media-breakpoint-up(lg) {
                position: static;
            }

            .items {
                .item.home {

                    a:hover,
                    a:active,
                    a:focus {
                        color: $secondary-color;
                        text-decoration: none;
                    }
                }
            }
        }

        .sortby-links {
            font-size: 1.8rem;
            text-align: center;
            display: block;

            @include media-breakpoint-up(md) {
                float: right;
                display: inline-block;
                position: absolute;
                top: 2rem;
                right: 0;
            }

            @include media-breakpoint-up(lg) {
                right: unset;
                left: 20%;
                width: 20rem;
            }

            a:hover,
            a:active,
            a:focus {
                color: $secondary-color;
            }

            select {
                padding: 5px 0 3px 0;
            }
        }

        .back-to-overview-wrap {
            display: none;


            @include media-breakpoint-up(lg) {
                display: inline-block;
                float: right;
                padding-top: 0;
                cursor: pointer;
                position: absolute;
                top: 2rem;
                right: 0;
            }



            .back-to-overview {

                &:hover,
                &:active,
                &:focus {
                    color: $secondary-color;
                }
            }
        }
    }



    .columns {
        .column.main {

            .category-message {
                text-align: center;
                display: block;
                font-size: 2rem;
                font-weight: bold;
                color: black;
                text-transform: uppercase;
                width: max-content;
                margin: 0 auto;

                &:hover {
                    border-bottom: 7px solid #16315b;
                }
            }


            .category-list {
                width: 100%;

                @include media-breakpoint-up(md) {
                    width: 33%;
                    position: absolute;
                    top: 1rem;
                    left: 0;
                }

                @include media-breakpoint-up(lg) {
                    width: 25%;
                }

                .items {
                    .item {
                        padding: 0.5rem 0 0.5rem 1rem;
                        margin-bottom: 1rem;

                        a {
                            font-size: 2rem;
                            font-weight: 300;
                            display: block;
                            position: relative;

                            &:hover,
                            &:focus,
                            &:active,
                            &.active {
                                color: #16315b;
                                font-weight: 700;

                                &::before {
                                    content: '';
                                    width: 100%;
                                    height: 7px;
                                    position: absolute;
                                    left: 0;
                                    bottom: -6px;
                                    background: #16315b;
                                }
                            }
                        }

                        .subcategories {
                            margin-top: 12px;
                            margin-bottom: -6px;

                            .item {
                                margin-bottom: 0;
                                padding-bottom: 0;
                            }
                        }

                    }


                }
            }

            .product-item {
                margin-bottom: 2rem;

                @include media-breakpoint-up(lg) {
                    &:first-child {
                        width: 25%;
                    }
                }

                /*                &:first-child{
                    @include media-breakpoint-down(sm){
                        display: none;
                    }
                }*/

                .product-item-info {
                    .product-item-details {
                        position: relative;

                        .product-item-name {
                            border-bottom: 1px solid $dusty-gray;
                            max-width: 80%;
                            min-height: 52px;

                            a {
                                font-size: 1.8rem;
                                font-weight: bold;
                            }

                            .product-item-actions {
                                margin: 0;
                                display: block;

                                .actions-primary {
                                    .tocart {
                                        padding: 1rem;
                                        background-color: transparent;
                                        border: none;
                                        position: absolute;
                                        right: 0;
                                        top: 50%;
                                        transform: translateY(-50%);

                                        img {
                                            max-width: 30px;
                                        }
                                    }
                                }
                            }
                        }
                    }

                    .product-item-photo {
                        &>a {
                            display: block;

                            .product-image-container {
                                width: 100% !important;

                                img {
                                    width: 100%;
                                }
                            }
                        }
                    }
                }

                .price-box {
                    margin: 0;

                    .price {
                        font-weight: normal;
                        font-size: 1.8rem;
                    }
                }

                .price-label {
                    display: none;
                }

                .product-item-inner {
                    .actions-secondary {
                        display: none;
                    }
                }
            }
        }
    }
}

.page-products {
    .products-grid {
        .product-items {
            margin-left: 0;

            @include media-breakpoint-up(sm) {
                margin-right: -2rem;
            }

            .product-item {
                width: 100%;
                margin-left: 0;
                padding-right: 2rem;
                text-align: right;

                @include media-breakpoint-up(sm) {
                    width: 50%;
                }

                @include media-breakpoint-up(md) {
                    width: 33.333%;
                }

                @include media-breakpoint-up(lg) {
                    width: 25%;
                }

                .product-item-info {
                    display: inline-block;
                    text-align: left;
                }
            }
        }
    }
}



.old-price,
.old.price {
    text-decoration: line-through;
}

.prices-tier {
    .price-container {
        .price-including-tax {
            +.price-excluding-tax {
                &:before {
                    content: '(' attr(data-label) ': ';
                }

                &:last-child:after {
                    content: ')';
                }
            }
        }

        .weee[data-label] {
            display: inline;

            .price {
                @include lib-font-size(11);
            }

            &:before {
                content: ' +' attr(data-label) ': ';
            }
        }
    }
}

.actual-price {
    font-weight: $font-weight__bold;
}

.product.name a {
    @extend .abs-product-link;
}

.category-image {
    .image {
        display: none;
        height: auto;
        max-width: 100%;
    }
}

.category-image,
.category-description {
    margin-bottom: $indent__base;
}

//
//  Product images general container
//  ---------------------------------------------

.product-image-container {
    display: inline-block;
    max-width: 100%;
}

.product-image-wrapper {
    display: block;
    height: 0;
    overflow: hidden;
    position: relative;
    z-index: 1;
}

.product-image-photo {
    bottom: 0;
    display: block;
    height: auto;
    left: 0;
    margin: auto;
    max-width: 100%;
    position: absolute;
    right: 0;
    top: 0;
}

//
//  Product view
//  ---------------------------------------------

/*  -----  ALEX  -----  */

.catalog-product-view {
    .columns {
        @include media-breakpoint-up(sm) {
            margin-top: 1rem;
        }
    }

    .data {
        .switch {
            font-size: 1.7rem;
            font-weight: bold;
        }
    }

    .back-to-overview-wrap {
        display: inline-block;
        float: right;
        padding-top: 0;
        cursor: pointer;
        position: absolute;
        top: 2rem;
        right: 2rem;
    }

    .page-title-wrapper {
        display: none;
    }

    .fotorama__caption {
        display: none;
    }

    .fotorama__stage__frame {
        .fotorama__img {
            width: 85%;
        }
    }

    .columns {
        .column.main {
            display: inline;

            .product-info-main {
                font-size: 1.4rem;

                .product-like-wrapper {
                    margin: 2rem 0;

                    .like-count {
                        display: none;
                    }

                    .like-product {
                        background: transparent;
                        border: none;
                        padding: 0;

                        span {
                            line-height: 1.5;
                            font-size: 1.4rem;
                        }

                        &::after {
                            display: inline-block;
                            content: "\f004";
                            font-family: 'Font Awesome 5 Free';
                            height: 17px;
                            width: 20px;
                            font-weight: 400;
                            margin-left: 1rem;

                        }


                    }

                    &.liked {
                        .like-product {
                            &::after {
                                font-weight: 600;
                            }
                        }
                    }
                }

                .swatch-opt {
                    .swatch-attribute {
                        margin-bottom: 1rem;

                        .swatch-select.size {
                            max-width: 200px;
                        }
                    }

                    .swatch-option.text {
                        line-height: 1;
                    }
                }

                .product-add-form {
                    .additional-attributes-wrapper {
                        max-width: 50%;

                        .additional-attributes {
                            ul {
                                li {
                                    span {
                                        padding: 0;
                                    }
                                }
                            }

                            .attribute-label,
                            .attribute-value {
                                display: inline-block;
                            }
                        }

                        @media(max-width: 767px) {
                            max-width: initial;
                        }
                    }

                    .box-tocart {
                        display: block;
                        width: 100%;

                        .field.qty {
                            display: block;
                            text-align: right;
                            padding-right: 0;
                            float: right;

                            .control {
                                .label {
                                    font-size: 1.8rem;
                                    font-weight: 300;
                                    margin-right: 1rem;
                                }

                                .input-text.qty {
                                    padding: 1rem;
                                    height: auto;
                                    background-color: transparent;
                                }
                            }
                        }

                        .actions {
                            display: block;
                            text-align: right;
                            padding-top: 0;
                            clear: both;

                            @include media-breakpoint-down(md) {
                                max-width: 300px;
                                margin: 1rem auto 0;
                            }

                            .action.primary.tocart,
                            .action.primary.checkout {
                                border-radius: 0;
                                border: none;
                                padding: 1rem;
                                line-height: 1;
                                @include lib-font-size(17);
                                background-color: $secondary-color;
                                color: $white;
                                margin-left: 1rem;
                                text-align: center;
                                width: 100%;
                                margin-bottom: 1em;

                                @include media-breakpoint-down(md) {
                                    margin-left: auto;
                                    clear: both;
                                    margin-top: 0;
                                    margin-bottom: 2rem;
                                    max-width: 300px;
                                }

                                @include media-breakpoint-up(xl) {
                                    width: calc(33% - 0.6667rem);

                                    &:last-child {
                                        margin-left: 0;
                                    }
                                }

                                &:hover,
                                &:active,
                                &:focus {
                                    background-color: $primary-color;
                                    color: $secondary-color;
                                }
                            }

                            .action.primary.checkout {
                                display: none;
                            }
                        }

                        .accordion.items {
                            display: none;
                        }
                    }
                }

                .product-info-price {
                    margin-bottom: 2rem;

                    .product-name {
                        float: left;

                        .value {
                            @include lib-font-size(30);
                            font-weight: 700;
                            line-height: 1;
                        }
                    }

                    .price-box {
                        margin-top: 16px;
                        float: right;

                        .price-container {
                            @include lib-font-size(27);
                            line-height: 1;
                        }
                    }

                    .sku {
                        margin-top: 2rem;
                        clear: both;
                        width: 100%;

                        .type {
                            display: inline-block;
                        }

                        &>strong,
                        &>div {
                            font-weight: normal;
                        }
                    }
                }
            }
        }

        .block.related {
            max-width: 57%;
            clear: both;

            @include media-breakpoint-down(md) {
                max-width: 100%;
            }

            .block-title {
                &>strong {
                    font-size: 3rem;
                }
            }

            .block-content {
                .products-related {
                    margin-top: 0;

                    .product-items {
                        margin-top: 0;

                        .product-item {
                            width: 33.3333%;

                            .product-item-info.related-available {
                                width: 100%;

                                .product-image-photo {
                                    background-color: transparent;
                                    width: 100%;
                                    margin: 0;
                                }
                            }

                            .product-item-details {
                                display: none;
                            }
                        }
                    }
                }

                .block-actions {
                    display: none;
                }
            }
        }
    }

    .product-social-links {
        .action.towishlist {
            background-color: transparent;
            border: none;
            border-radius: 0;
            padding: 0;
            line-height: 1.5;
            font-size: 1.4rem;

            &:hover {
                background-color: transparent;
            }
        }
    }
}

.product.media {
    .product.photo .photo.image {
        @extend .abs-adaptive-images-centered;
    }

    .placeholder .photo.container {
        max-width: 100%;
    }

    .notice {
        @include lib-css(color, $text__color__muted);
        @include lib-font-size($font-size__s);
        margin: $indent__s 0;
    }

    .product.thumbs {
        margin: $indent__base 0 $indent__l;
    }

    .items.thumbs {
        @include lib-list-inline();

        .active {
            display: block;
            line-height: 1;
        }
    }
}

.product.info.detailed {
    clear: both;
    margin-bottom: 30px;

    .additional-attributes {
        width: auto;
        @include lib-table-resize($_th-padding-left : 0,
            $_th-padding-right : $indent__l,
            $_th-padding-bottom : $indent__s,
            $_td-padding-bottom : $indent__s );
    }
}

.product-info-main {
    .page-title-wrapper {
        .page-title {
            line-height: $line-height__base;
            margin-bottom: $indent__s;
        }
    }

    .stock {

        &.available,
        &.unavailable {
            display: inline-block;
            font-weight: $font-weight__bold;
            margin-right: $indent__base;
            text-transform: uppercase;
            vertical-align: top;
        }
    }

    .product {
        &.attribute {
            &.sku {
                display: inline-block;
                vertical-align: top;
                @include lib-css(color, $text__color__muted);

                >.value {
                    display: inline-block;
                    vertical-align: top;
                    word-break: break-all;
                }

                .type {
                    margin-right: $indent__xs;
                }
            }

            &.overview {
                margin: $indent__base 0;
            }
        }

        &.alert {
            margin: $indent__s 0;
        }
    }

    .price-box {
        margin-top: $indent__s;
    }

    .product-reviews-summary .reviews-actions {
        @include lib-font-size($font-size__base);
    }
}

.product-options-wrapper {
    .fieldset-product-options-inner {
        .legend {
            @include lib-css(font-weight, $font-weight__bold);
            @include lib-css(margin, 0 0 $indent__xs);
            @include lib-font-size(14px);
            border: none;
            display: inline-block;
            float: none;
            padding: 0;
        }

        //  Date & Time custom option (Affect Time that goes only after Date)
        input.datetime-picker {
            ~select.datetime-picker {
                margin-top: $indent__s;
            }
        }

        &.required,
        &._required {
            .legend {
                &:after {
                    content: '*';
                    @include lib-typography($_font-size : $form-field-label-asterisk__font-size,
                        $_color : $form-field-label-asterisk__color,
                        $_font-family : $form-field-label-asterisk__font-family,
                        $_font-weight : $form-field-label-asterisk__font-weight,
                        $_line-height : $form-field-label-asterisk__line-height,
                        $_font-style : $form-field-label-asterisk__font-style );
                    @include lib-css(margin, $form-field-label-asterisk__margin);
                }
            }
        }
    }

    .field {
        .note {
            display: block;
        }

        .price-notice {
            @extend .abs-adjustment-incl-excl-tax;
        }
    }
}

.product-info-main,
.product-options-bottom {
    .price-box {

        .price-including-tax+.price-excluding-tax,
        .weee+.price-excluding-tax,
        .weee {
            @include lib-font-size(12);
            line-height: 14px;
            margin-bottom: $indent__xs;

            .price {
                @include lib-font-size(12);
                font-weight: $font-weight__bold;
            }
        }

        .price-wrapper .price {
            @include lib-font-size(30);
            font-weight: $font-weight__bold;
        }

        .price {
            white-space: nowrap;
        }
    }

    .special-price {
        display: block;
        margin: $indent__s 0;

        .price-container {
            @include lib-font-size(14);
        }

        .price-label+.price-wrapper {
            display: inline-block;
        }
    }

    .old-price,
    .special-price {
        .price-label {
            &:after {
                content: ': ';
            }
        }
    }

    .box-tocart {
        margin: $indent__base 0;

        .field.qty {
            padding-right: 0.75 * $indent__base;
        }

        .input-text.qty {
            $tocart-input-size: $button__line-height__l +30px;
            height: $tocart-input-size;
            text-align: center;
            width: auto;
        }

        .actions {
            text-align: center;
        }

        .action.tocart {
            @extend .abs-button-l;
        }
    }

    .product-addto-links {
        .towishlist {
            &:after {
                display: inline-block;
                content: "\f004";
                font-family: 'Font Awesome 5 Free';
                height: 17px;
                width: 20px;
                font-weight: 400;
                margin-left: 1rem;
            }
        }
    }

    .action.tocompare {
        @extend .abs-action-addto-product;
        vertical-align: top;
    }
}

.prices-tier {
    @extend .abs-reset-list;
    @include lib-css(background, $sidebar__background-color);
    margin: $indent__s 0;
    padding: $indent__s (
        0.75 * $indent__base
    );

.price-container {
    display: inline-block;
}

.price-including-tax,
.price-excluding-tax,
.weee {
    display: inline-block;

    .price {
        @include lib-font-size(14);
        font-weight: $font-weight__bold;
    }
}
}

.ui-dialog-titlebar-close {
    @include lib-button-as-link();
}

.block.related {
    .action.select {
        margin: 0 $indent__xs;
    }
}

//
//  Sidebar product view
//  ---------------------------------------------

.sidebar {
    .product-items {
        .product-item {
            margin-bottom: $indent__base;
            position: relative;
        }

        .product-item-info {
            position: relative;
            width: auto;

            .product-item-photo {
                left: 0;
                position: absolute;
                top: 0;
            }
        }

        .product-item-name {
            margin-top: 0;
        }

        .product-item-details {
            margin: 0 0 0 85px;
        }

        .product-item-actions {
            display: block;
            margin-top: $indent__s;
        }

        .price-box {
            display: block;
            margin: 7px 0;
        }

        .text {
            margin-right: 8px;
        }

        .counter {
            @include lib-css(color, $primary__color__lighter);
            @include lib-font-size(12);
            white-space: nowrap;
        }

        .minilist {
            .price {
                display: inline;
                padding: 0;
            }

            .weee:before {
                display: inline-block;
            }
        }
    }

    .action {
        &.delete {
            @extend .abs-remove-button-for-blocks;
            position: absolute;
            right: 0;
            top: 0;
        }
    }

    .subtitle {
        @extend .abs-no-display;
    }

    //
    //  Product images only
    //  ---------------------------------------------

    .product-items-images {
        @extend .abs-add-clearfix;
        margin-left: -$indent__xs;

        .product-item {
            @extend .abs-add-box-sizing;
            float: left;
            padding-left: $indent__xs;
        }
    }

    //
    //  Product names only
    //  ---------------------------------------------

    .product-items-names {
        .product-item {
            margin-bottom: $indent__s;
        }

        .product-item-name {
            margin: 0;
        }
    }
}

.product-info-main {
    .type {
        display: none;
    }
}

//
//  Mobile
//  _____________________________________________

@include max-screen($screen__m) {
    .catalog-product-view {
        .column.main {
            @include lib-vendor-prefix-display(flex);
            @include lib-vendor-prefix-flex-direction(column);
        }

        .product.media {
            @include lib-vendor-prefix-order(-1);
        }
    }

    .product-info-main .box-tocart {
        .actions {
            .action.tocart {
                @extend .abs-button-responsive-smaller;
            }
        }
    }

    .block.related {
        .action.select {
            display: block;
            margin: $indent__xs 0;
        }
    }

    .compare,
    .product-addto-links .action.tocompare,
    .product-item-actions .actions-secondary>.action.tocompare,
    [class*='block-compare'] {
        display: none;
    }
}

//
//  Desktop
//  _____________________________________________

@include min-screen($screen__m) {

    .product-info-main,
    .product-options-bottom {
        .box-tocart {
            display: table;

            .field.qty {
                display: table-cell;
            }

            .actions {
                display: table-cell;
                padding-top: $indent__m;
                text-align: center;
                vertical-align: bottom;
            }
        }
    }

    .product-info-main {
        .page-title-wrapper {
            .page-title {
                margin-top: -13px;
            }
        }
    }

    .sidebar {
        .product-items {
            .product-item-info {
                .product-item-photo {
                    float: left;
                    left: auto;
                    margin: 0 $indent__s $indent__s 0;
                    position: relative;
                    top: auto;
                }
            }

            .product-item-details {
                margin: 0;
            }

            .product-item-actions {
                clear: left;
            }
        }
    }

    .product-add-form {
        @extend .abs-revert-field-type-desktop;
    }
}

//
//  Desktop large
//  _____________________________________________

@include min-screen($screen__xl) {
    .sidebar {
        .product-items {
            .product-item-info {
                .product-item-photo {
                    float: none;
                    left: 0;
                    margin: 0;
                    position: absolute;
                    top: 0;
                }
            }

            .product-item-details {
                margin-left: 85px;
            }
        }
    }
}

//
//  Category page layout
//  ---------------------------------------------

@include min-screen($screen__m) {
    .product-info-main {
        float: right;
    }

    .product.media {
        float: left;
        margin-bottom: $indent__m;
    }

    .page-layout-1column {
        .product-info-main {
            width: 40%;
        }

        .product.media {
            width: 57%;
        }
    }

    .page-layout-2columns-left,
    .page-layout-2columns-right,
    .page-layout-3columns {
        .product-info-main {
            width: 48%;
        }

        .product.media {
            width: 50%;
        }
    }
}

//
//  Compare Products Page
//  ---------------------------------------------

body.catalog-product-compare-index {
    .action.print {
        float: right;
        margin: 15px 0;
    }
}

.table-wrapper.comparison {
    clear: both;
    max-width: 100%;
    overflow-x: auto;
}

.table-comparison {
    table-layout: fixed;

    .cell.label.remove,
    .cell.label.product {
        span {
            @extend .abs-visually-hidden;
        }
    }

    .cell.label,
    td:last-child {
        border-right: $table__border-width $table__border-style $table__border-color;
    }

    .cell {
        padding: 15px;
        width: 140px;

        .attribute.value {
            overflow: hidden;
            width: 100%;
        }

        &.product.info,
        &.product.label {
            border-bottom: $table__border-width $table__border-style $table__border-color;
        }

        &.label {
            .attribute.label {
                display: block;
                width: 100%;
                word-wrap: break-word;
            }
        }

        &.attribute {
            @include lib-font-size(13);

            img {
                height: auto;
                max-width: 100%;
            }
        }
    }

    .product-item-photo {
        display: block;
        margin: 0 auto 15px;
    }

    .product-image-photo {
        margin-left: 0;
    }

    .product-item-actions,
    .price-box,
    .product.rating,
    .product-item-name {
        display: block;
        margin: 15px 0;
    }

    .product-addto-links {
        margin-top: 15px;

        .action.split,
        .action.toggle {
            @include lib-button-s();
        }

        .action.toggle {
            padding: 0;
        }
    }

    .cell.remove {
        padding-bottom: 0;
        padding-top: 0;
        text-align: right;

        .action.delete {
            @extend .abs-remove-button-for-blocks;
        }
    }

    .product-item-actions {
        >.actions-primary {
            +.actions-secondary {
                margin-top: $indent__s;
            }
        }
    }

    .action {
        &.tocart {
            white-space: nowrap;
        }
    }
}

.comparison.headings {
    @include lib-css(background, $page__background-color);
    left: 0;
    position: absolute;
    top: 0;
    width: auto;
    z-index: 2;
}

.block-compare {
    .block-title {
        @extend .abs-block-title;
    }

    .product-item .product-item-name {
        margin-left: 22px;
    }

    .action {
        &.delete {
            @extend .abs-remove-button-for-blocks;
            left: -6px;
            position: absolute;
            top: 0;
        }

        &.compare {
            @extend .abs-revert-secondary-color;
        }
    }

    .counter {
        @extend .abs-block-items-counter;
    }

    .actions-toolbar {
        margin: 17px 0 0;
    }
}

.quickbuy-modal {
    .modal-inner-wrap {
        border-radius: 0;
    }
}

$monitorBg: url('../images/stock.png');
$monitorItemHeight: 26px;
$monitorItemWidth: 14px;

.stock-indicator-wrapper {
    display: inline-block;

    .stock-indicator-monitor {

        height: $monitorItemHeight;
        width: auto;
        display: inline-block;
        background: transparent;
        border: 0;

        span {
            background: $monitorBg;
            background-size: auto $monitorItemHeight;
            backgrond-repeat: repeat-x;
        }

        @for $i from 1 through 10 {
            .monitor-#{$i} {
                width: #{$monitorItemWidth*$i};
                display: block;
                height: $monitorItemHeight;
            }
        }
    }
}

.catalog-category-view,
.catalog-product-view {
    .page-main {
        position: relative;
    }
}

.catalog-category-view,
.catalogsearch-result-index {

    .page-main {

        @include media-breakpoint-up(lg) {
            min-height: 60rem;
        }
    }

    .quickbuy-modal {
        #product_addtocart_form {
            #qty {
                padding-left: 15px;
                padding-right: 15px;
            }
        }
    }
}

.catalog-view-banner {
    display: none;
}

//Removed price from printed matter category
.category-printed-matter {
    .columns {
        .column.main {
            .product-item {
                .product-item-info {
                    .product-item-details {
                        .price-box {
                            display: none;
                        }
                    }
                }
            }
        }
    }
}


.block-content.filter-content.category-list {
    background-color: $background-color;
}


.price-container .price-label {
    display: none !important;

}
.page-wrapper{
    .page-footer{
        background-color: $secondary-color;
        width: 100%;
        height: auto;

        .footer.content{
            border: none;

            .top-footer{
                display: inline-block;

                .footer_menu{
                    @include media-breakpoint-down(sm){
                        margin-bottom: 4rem;
                    }

                    span{
                        font-size: 2.5rem;
                        color: $white;
                        font-weight: 600;

                        &:before{
                            content: "";
                            display: block;
                            position: relative;
                            background: $white;
                            width: 130px;
                            height: 7px;
                            bottom: 18px;
                        }
                    }

                    ul{
                        margin-top: 2rem;
                        border-bottom: 1px solid $white;
                    }

                    li{
                        border-top: 1px solid $white;
                    }

                    a{
                        padding: 8px 0 8px 25px;
                        display: block;
                        color: $white;

                        &:hover,
                        &:active,
                        &:focus {
                            color: $primary-color;
                        }

                        &:before{
                            content: ">";
                            margin-right: 0.5rem;
                        }
                    }
                }
            }

            .bottom-footer{
                @include media-breakpoint-down(sm){
                    float: none;
                }

                //display: inline-block;
                display: none;
                float: right;

                .footer_menu_right{
                    span{
                        font-size: 2.5rem;
                        color: $white;
                        font-weight: 600;

                        &:before{
                            content: "";
                            display: block;
                            position: relative;
                            background: $white;
                            width: 130px;
                            height: 7px;
                            bottom: 18px;
                        }
                    }

                    ul{

                        margin-top: 2rem;
                        border-bottom: 1px solid $white; 
                    }

                    li{
                        border-top: 1px solid $white;

                    }

                    a{
                        padding: 8px 0 8px 25px;
                        display: block;
                        color: $white;
                        &:hover,
                        &:active,
                        &:focus {
                            color: $primary-color;                     
                        }
                        &:before{
                            content: ">";
                            margin-right: 0.5rem;
                        }

                    }                                       
                }
            }

            .colofon.nav{
                justify-content: center;

                @include media-breakpoint-down(sm){
                    flex-direction: column;
                    text-align: center;
                }

                & > li{
                    color: $white;
                    margin-right: 3rem;

                    @include media-breakpoint-down(sm){
                        margin-right: 0;
                        display: block;
                        padding: 0.7rem 0;
                    }

                    &:last-child{
                        margin-right: 0;
                    }

                    & > a{
                        color: $white;
                    }
                }
            }

            .footer-social{
                margin-top: 2rem;
                .social{
                    a {
                        width: 40px;
                        padding-bottom: 40px;
                        float: left;
                        display: inline-block;                        
                        background: $white;
                        text-align: center;
                        position: relative;
                        margin: 0 0.5em;

                        &::after {
                            font-size: 1.6em;
                            width: 100%;
                            left: 0;                            
                            position: absolute;
                            top: 50%;
                            transform: translateY(-50%);
                            @extend .fab; 
                            color: $secondary-color;
                        }

                        &:first-child {
                            margin-left: 0;
                        }

                        &:last-child {
                            margin-right: 0;
                        }

                    }

                    .vimeo{
                        &::after{                            
                            content: fa-content($fa-var-vimeo-v);                            
                        }
                    }

                    .youtube{
                        &::after{
                            content: fa-content($fa-var-youtube);

                        }
                    }

                    .linkedin{
                        &::after{
                            content: fa-content($fa-var-linkedin-in);
                        }
                    }

                    .facebook{
                        &::after{
                            content: fa-content($fa-var-facebook-f);
                        }                        
                    }

                    .twitter{
                        &::after{
                            content: fa-content($fa-var-twitter);
                        }        
                    }
                }
            }
        }
    }
}
.page-wrapper{
    .page-main{
        min-height: 70vh;
    }
}
// Header
.page-wrapper {
    .page-header {
        @include media-breakpoint-down(md) {
            margin-bottom: 0;
        }

        .block-search {
            @include media-breakpoint-down(md) {
                width: 200px;
            }

            @include media-breakpoint-down(sm) {
                width: 100%;
            }
        }

        .panel.wrapper {
            .panel.header {
                .nav-toggle {
                    @include media-breakpoint-down(sm) {}
                }

                strong.logo {
                    @include media-breakpoint-down(sm) {
                        width: 100%;
                        max-width: initial;
                        margin-left: 0;
                        display: inline-block;
                        text-align: center;
                    }

                    img {
                        @include media-breakpoint-down(sm) {
                            display: inline-block;
                            max-width: 150px;
                            margin-top: 1rem;
                        }
                    }
                }

                .minicart-wrapper {
                    @include media-breakpoint-down(sm) {
                        float: none;
                        margin-top: 0;
                        position: absolute;
                        top: 2rem;
                        right: 1rem;
                        z-index: 14;
                    }

                    .action.showcart {
                        @include media-breakpoint-down(sm) {
                            margin: 0;
                        }

                        .counter.qty {
                            @include media-breakpoint-down(sm) {
                                margin: 0;
                            }
                        }
                    }
                }
            }
        }

        .header.content {
            padding-top: 15px;

            @include media-breakpoint-down(sm) {
                clear: both;
            }

            @include media-breakpoint-up(md) {
                height: 50px;
            }


            .nav-container {
                padding-top: 0;
                position: static;
                height: auto;
                overflow: hidden;

                .header-mobile-buttons {
                    .action.primary {
                        width: 49%;
                    }

                    @include min-screen($screen__m) {
                        display: none;
                    }
                }

                .header-menu {
                    padding-left: 0;
                    display: none;

                    span {
                        display: none;
                    }

                    @include min-screen($screen__m) {
                        display: block;
                        //background: $nomad;

                        >ul {
                            position: relative;
                            line-height: 0;
                            text-align: left;
                            white-space: nowrap;
                            margin: 0;
                            padding-left: 0;

                            li {
                                display: block;
                                padding-left: 0;
                                margin-right: 1em;
                                text-align: center;
                                border-bottom: 7px solid transparent;
                                padding: 10px 0rem;
                                padding-left: 0;
                                border-bottom: 7px solid transparent;
                                padding-top: 0;
                                margin: 0;
                                margin-right: 8px;


                                &:hover,
                                &.current {
                                    border-bottom: 7px solid #16315b;
                                }

                                >a {
                                    //color: $white;
                                    text-transform: uppercase;
                                    display: block;
                                    padding: 1rem 0.5rem;
                                    padding-left: 0;
                                    font-weight: 600;
                                    transition: all .2s ease 0s;
                                    font-size: 1.5rem;

                                    @include media-breakpoint-between(sm, md) {
                                        font-size: 1.3rem;
                                    }

                                    &:hover {
                                        background: none;
                                        color: #16315b;
                                        outline: 0;
                                    }
                                }

                                &.level-top {
                                    position: relative;
                                    display: inline-block;

                                    >ul {
                                        top: 100%;
                                        left: 0;
                                        padding-top: 20px;
                                        margin-top: 0.7rem;
                                    }
                                }

                                &:not(.level-top).parent {
                                    position: relative;

                                    >ul {
                                        top: 0;
                                        left: 100%;
                                    }
                                }
                            }

                            .how-to-order-b2b {

                                @include media-breakpoint-up(xl) {
                                    position: absolute !important;
                                    top: 0;
                                    right: 24%;
                                }
                            }

                            li ul {
                                display: none;
                                position: absolute;
                                top: 0;
                                left: 100%;
                                z-index: 100;
                                background: rgba(255, 255, 255, 0.95);
                                //border: 1px solid $dusty-gray2;
                            }

                            &:hover>ul {
                                display: block;
                            }
                        }
                    }
                }
            }
        }
    }

    .nav-sections {

        // .section-items {
        //     display: none;
        // }
        .category-menu {
            span {
                @include media-breakpoint-down(sm) {
                    display: none;
                }
            }

            .category-menu__list {
                @include media-breakpoint-down(sm) {
                    padding: 0;
                }
            }
        }
    }
}

.action.primary,
a.action.primary {

    &,
    &:hover,
    &:active,
    &:focus {
        border-radius: 0;
        border: none;
    }
}

.breadcrumbs {
    @include media-breakpoint-up(lg) {
        padding: 0;
        margin-top: 2rem;
    }
}

.page-wrapper {
    .back-to-overview-wrap {
        @include media-breakpoint-down(sm) {
            display: none;
        }
    }
}

.modal-popup {
    .modal-inner-wrap {
        border-radius: 0;
    }

    &.modal-slide {
        @include media-breakpoint-down(sm) {
            left: 4.8rem;
        }

        .modal-inner-wrap {
            @include media-breakpoint-down(sm) {
                max-width: 100%;
            }

            .modal-content {
                &>iframe {
                    max-width: 100%;
                }
            }
        }
    }
}

.page-main {
    @include media-breakpoint-down(sm) {
        margin-top: 2rem;
    }
}

button.action {
    background-color: $secondary-color;
    color: $white;
    border-radius: 0;
    border: none;
    text-transform: uppercase;

    &>span {
        display: inline-block;
        padding-top: 3px;
    }

    &:hover,
    &:focus,
    &:active {
        background-color: $primary-color;
        color: $secondary-color;
        border: none;
    }
}

.configurable-quick-buy {
    margin-bottom: 20px;
}

.field {
    .control {
        &._with-tooltip {
            display: flex;
            justify-content: flex-start;
            align-items: center;
            align-content: center;

            .field-tooltip {
                position: relative;
                display: flex;
                justify-content: flex-start;
                align-content: center;
                align-items: center;
            }

        }
    }
}

.login-container {
    .block-pitbulk-saml2-login-container {
        .block-title {
            display: none;
        }

        .block-content {
            .action.login.primary {
                float: left;
            }
        }
    }
}

input[type="date"] {
    background: #fff;
    background-clip: padding-box;
    border: 1px solid #c2c2c2;
    border-radius: 1px;
    font-family: futura-pt-alt, futura-pt, Arial, sans-serif;
    font-size: 12px;
    height: 32px;
    line-height: 1.42857;
    padding: 0 9px;
    vertical-align: baseline;
    width: 100% !important;
    box-sizing: border-box;

    &.mage-error {
        border-color: #ed8380;
    }
}

body{
    background-color: $background-color;
}

// Header menu

.page-wrapper{
    .page-header{
        background-color: $beam-color;

        .panel.wrapper{
            background-color: $white;

            .panel.header{
                padding-bottom: 0;

                    @include media-breakpoint-up(xl) {
                        position: relative;
                    }
                    .greet.welcome{
                        display: inline-block;
                        font-size: 1.6rem;

                        @include media-breakpoint-up(xl) {
                            position: absolute;
                            top: 22%;
                            left: 30%;
                        }
                    }                

                .logo{
                    width: 100%;
                    max-width: 18rem;
                }

                .minicart-wrapper{
                    .action.showcart{
                        margin: 0 0.8rem;
                        font-size: 1.6rem;

                        &::before{
                            @include media-breakpoint-up(md){
                                display: none;
                            }
                        }
                    }

                    .text{
                        @include media-breakpoint-up(md){
                            clip: unset;
                            height: auto;
                            width: auto;
                            margin: 0;
                            position: static;
                        }
                    }
                }

                .switcher.currency{
                    margin: 0 0.5em;
                    float: right;

                    .options{
                        font-size: 1.6rem;
                    }
                }

                .header-panel-content{
                    float: right;
                    font-size: 1.6rem;

                    a{
                        margin: 0 0.8em;
                    }

                    & > li{
                        @include media-breakpoint-down(sm){
                            display: none;
                        }

                        border-right: 1px solid $black;
                        display: inline-block;
                    }
                }

                .header.links{
                    font-size: 1.6rem;
                    padding-left: 0;

                    .item.link.compare{
                        display: none;
                    }

                    .greet.welcome{
                        display: none;
                    }

                    & > li{
                        border-right: 1px solid $black;
                        display:inline-block;
                        margin-left: 0;
                    }

                    a{
                        font-size: 1.6rem;
                        margin: 0 0.8em;
                    }
                }
            }
        }
    }
}

// Main Banner Home Page

.page-wrapper{
    .header-banner{
        .header-banner-wrap{
            position: relative;

            .scroller-wrapper{
                position: relative;

                .down-scroller{
                    // content: url("../images/dropdown_arrow.png");
                    background: url(../images/dropdown_arrow.png);
                    background-size: contain;
                    position: absolute;
                    width: 13rem;
                    bottom: 0rem;
                    height: 7rem;
                    right: 2rem;
                    cursor: pointer;

                    @include media-breakpoint-down(sm){
                        display: none;
                    }
                }
            }

            .header-banner-image{
                text-align: center;
                @include media-breakpoint-down(sm){
                    overflow: hidden;

                    img{
                        @include media-breakpoint-down(sm){                            
                            max-width: 100rem;

                        }
                    }
                }
            }

            .header-banner-text{                
                position: absolute;
                top: 50%;
                transform: translateY(-50%);
                width: 100%;
                align-items: center;

                h1{
                    @include media-breakpoint-down(lg){
                        font-size: 4.5rem;
                    }

                    @include media-breakpoint-down(md){
                        font-size: 3.5rem;
                    }

                    @include media-breakpoint-down(sm){
                        font-size: 3rem;
                    }

                    font-size: 6rem;
                    color: $white;
                    font-weight: 600;
                    margin-bottom: 0;

                    &:before{
                        @include media-breakpoint-down(sm){
                            max-width: 100px;
                        }

                        content: "";
                        display: block;
                        max-width: 270px;
                        border: 6px solid $white;
                        position: relative;
                        top: -2rem;
                    }
                }

                .container{
                    padding: 0 20px;
                    font-size: 6.2em;
                    max-width: 100%;
                    max-width: 1180px;

                    .banner-button{
                        @include media-breakpoint-down(sm){
                            padding: 0.5rem 1rem;
                        }

                        background-color: $primary-color;
                        width: auto;
                        line-height: 3.06rem;
                        min-width: 29rem;
                        text-align: left;
                        padding: 0.5rem 15rem 0.5rem 1rem;
                        font-size: 2.8rem;
                        font-weight: inherit;

                        &:after{
                            @extend .fas;
                            display: inline-block;
                            font-size:2rem;                      
                            content: fa-content($fa-var-angle-right);
                            position: relative;
                            left: 1rem;
                            top: 0;
                            line-height: 3rem;
                            color: $black;
                        }
                    }
                }
            }
            // Main Banner Home Page B2P 
            .header-banner-text-b2p {                
                position: absolute;
                top: 50%;
                transform: translateY(-50%);
                width: 100%;
                align-items: center;

                h1{
                    @include media-breakpoint-down(lg){
                        font-size: 2.5rem;
                    }

                    @include media-breakpoint-down(md){
                        font-size: 1.8rem;
                    }

                    @include media-breakpoint-down(sm){
                        font-size: 1.6rem;
                    }

                    font-size: 4rem;
                    color: $white;
                    font-weight: 500;
                    margin-bottom: 0;
                    font-family: system-ui;
                    text-transform: uppercase;
                }

                .container{
                    padding: 0 20px;
                    font-size: 6.2em;
                    max-width: 100%;
                    max-width: 1180px;

                    .banner-button{
                        @include media-breakpoint-down(md){
                            font-size: 1.2rem;
                            padding: 0.2rem 3rem 0.2rem 1rem;
                        }
                        @include media-breakpoint-down(sm){
                            padding: 0.5rem 2rem;
                            font-size: 1rem;
                        }

                        background-color: $primary-color;
                        width: auto;
                        line-height: 3.06rem;
                        min-width: 29rem;
                        text-align: left;
                        padding: 0.2rem 4rem 0.2rem 1rem;
                        font-size: 1.8rem;
                        font-weight: 600;

                        &:after{
                            @extend .fas;
                            display: inline-block;
                            font-size:2rem;                      
                            content: fa-content($fa-var-angle-right);
                            position: relative;
                            left: 1rem;
                            top: 0;
                            line-height: 3rem;
                            color: $black;
                            @include media-breakpoint-down(md){
                                font-size: 1rem;
                            }
                        }
                    }
                }

                h3{
                    @include media-breakpoint-down(lg){
                        font-size: 2.5rem;
                    }

                    @include media-breakpoint-down(md){
                        font-size: 1.5rem;
                    }

                    @include media-breakpoint-down(sm){
                        font-size: 1.5rem;
                    }

                    font-size: 2.4rem;
                    color: $white;
                    font-weight: 500;
                    margin-bottom: 0;
                    margin-top: 0;
                    font-family: system-ui;
                    text-transform: uppercase;
                }
            }
        }
    }
}

.banner-button{
    border-radius: 0;
}

// Category box

.cms-index-index { 
    .page-wrapper{
        .page-main{
            .page-title-wrapper{
                display: none;
            }

            .columns{
                .column.main{
                    .home-movie-wrapper{
                        margin-top: 1rem;

                        @include media-breakpoint-down(sm){
                            margin-top: 4rem;
                        }
                    }

                    .category-wrapper {
                        @include media-breakpoint-down(sm){
                            margin: 2rem auto;
                            max-width: 100%;
                            flex: 0 0 100%;
                        }

                        margin-bottom: 2rem;

                        .category-text{
                            @include media-breakpoint-down(sm){
                                bottom: 16%;
                            }
                            z-index: 10;
                            width: 100%;                            
                            bottom: 17%; 
                            // background-color: $boskalis-white-color;
                            position: relative;
                            padding: 1rem 0 4rem 0;
                            margin-top: 23rem;

                            h2{
                                @include media-breakpoint-down(sm){
                                    padding-left: 1rem;
                                    font-weight: 600;
                                }

                                padding-left: 1rem;
                                font-weight: 900;
                                font-size: 3.7rem;
                                min-height: 8.1rem;
                                margin-top: 1.5rem;
                                margin-bottom: 0;
                                padding-top: 3rem;
                            }

                            span{
                                @include media-breakpoint-down(sm){
                                    float: right;                                    
                                    background-color: $primary_color;
                                    margin-right: 0.7rem;
                                }

                                float: right;                                    
                                background-color: $primary_color;
                                padding: 0.8rem 4.4rem;
                                font-size: 1.7rem;
                                font-weight: 700;

                                &::after{
                                    content: "";
                                    background: url("../images/yellow_arrow.png");
                                    background-size: 100% auto;
                                    display: inline-block;                                    
                                    position: absolute;
                                    right: 0;
                                    bottom: 0;
                                    width: 4.3rem;
                                    height: 4rem;                                    
                                }
                            }
                        }

                        .arrow-image {
                            display: none;
                            content: url("../images/yellow_arrow.png");
                            width: 4.3rem;
                            position: absolute;
                            top: 32.6rem;
                            left: 32.4rem;

                            @include media-breakpoint-down(sm){
                                top: 32.5rem;
                                left: 29.5rem;
                            }
                        }

                        .category-bottom {
                            /*background-color: $boskalis-grey-color;
                            min-height: 4rem;
                            box-shadow: 0.3rem 0.3rem 0.2rem 0.2rem #9ea7ac;
                            margin-top: 0rem;*/
                            display:none;
                        }
                    }

                    .category-link{
                        display: block;
                        position: relative;
                        border: 1px solid $boskalis-blue-color;
                        background-color: $boskalis-white-color;
                        //box-shadow: 0.3rem 0.3rem 0.2rem 0.2rem #9ea7ac;
                        width: 100%;

                        img{ 
                            position: absolute;
                            top: 1.8rem;
                            right: 2rem;
                            width: auto;
                            height: auto;
                            max-width: 25rem;
                            // max-height: 25rem;
                            z-index: 1;
                        }
                        .banner-category-image{
                            position: absolute;
                            min-width: 100%;
                            min-height: 23rem;
                            height: auto;
                            width: auto;
                            img{
                                position: relative;
                                min-width: 100%;
                                left: 0rem;
                                min-height: 23rem;
                                top: 0rem;
                                height: auto;
                                width: auto;
                                @include media-breakpoint-up(md){
                                    min-width: 100%;
                                }
                                @include media-breakpoint-up(lg){
                                    min-width: 100%;
                                }
                                @include media-breakpoint-up(xl){
                                    min-width: 100%;
                                }
                            }

                        }
                    }
                }

                .category-wrapper-inline {
                    position: relative;
                    background: none;

                    @include media-breakpoint-down(sm){
                        margin-top: 3.5rem;
                    }

                    .category-container{
                        position: relative;
                        padding-bottom: 100%;

                        @include media-breakpoint-down(sm){
                            padding-bottom: 0;
                        }

                        .category-box{
                            &:first-child{
                                top: 0;
                            }

                            min-height: 190px;
                            position: absolute;
                            top: 19.7rem;
                            height: 53%;
                            overflow: hidden;
                            width: 100%;

                            @include media-breakpoint-down(sm){
                                position: relative;
                                top: 0;
                                height: auto;
                                margin-bottom: 2rem;
                            }

                            .category-link{
                                min-height: 190px;
                                border: 1px solid $secondary-color;
                                padding: 0;
                                display: block;
                                position: absolute;
                                height: 100%;
                                width: 100%;
                                left: 0;
                                background: none;

                                img{
                                    width: auto;
                                    bottom: 0;
                                    right: 0;
                                    left: auto;
                                    max-width: 200px;
                                    height: auto;
                                    max-height: 100%;
                                    top: auto;
                                }
                            }

                            .category-text{
                                h2{
                                    @include media-breakpoint-down(sm){
                                        margin-top: 0.5rem;
                                    }

                                    padding-left: 1rem;
                                    color: #273f61;
                                    font-size: 3rem;
                                    font-weight: 900;

                                    &::after{
                                        content: "";
                                        background: url("../images/yellow_arrow.png");
                                        background-size: 100% auto;
                                        display: inline-block;                                    
                                        position: absolute;
                                        left: 0;
                                        bottom: 0;
                                        width: 4.3rem;
                                        height: 4rem;                                    
                                    }
                                }

                                span{
                                    display: none; 
                                }
                            }

                            .arrow-image2{
                                display: none;
                                content: url("../images/yellow_arrow.png");
                                width: 4.3rem;
                                position: absolute;
                                right: 31.5rem;
                                top: 14.9rem;

                                @include media-breakpoint-down(sm) {
                                    right: 28.5rem;
                                    top: 10.9rem;
                                }
                            }
                        }
                    }
                }           
            }
        }
    }
}

.home-movie-banner{
    .video-player{
        .container{
            .video-button{
                content: url("../images/play.png");
                position: absolute;
                width: 15rem;
                height: 15rem;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);

                @include media-breakpoint-down(sm){
                    width: 8rem;
                    height: 8rem;
                }
            }
        }
    }
}

@font-face{font-family:futura-pt-alt;font-weight:300;font-style:normal;
src:url(../fonts/futura-pt-alt/FuturaStdLight.eot);
src:url(../fonts/futura-pt-alt/FuturaStdLight.eot?#iefix) format("embedded-opentype"),
url(../fonts/futura-pt-alt/FuturaStdLight.woff2) format("woff2"),
url(../fonts/futura-pt-alt/FuturaStdLight.woff) format("woff"),
url(../fonts/futura-pt-alt/FuturaStdLight.ttf) format("truetype");
font-display: swap;
}

@font-face{font-family:futura-pt-alt;font-weight:400;font-style:normal;
src:url(../fonts/futura-pt-alt/FuturaStdBook.eot);
src:url(../fonts/futura-pt-alt/FuturaStdBook.eot?#iefix) format("embedded-opentype"),
url(../fonts/futura-pt-alt/FuturaStdBook.woff2) format("woff2"),
url(../fonts/futura-pt-alt/FuturaStdBook.woff) format("woff"),
url(../fonts/futura-pt-alt/FuturaStdBook.ttf) format("truetype");
font-display: swap;
}

@font-face{font-family:futura-pt-alt;font-weight:400;font-style:italic;
src:url(../fonts/futura-pt-alt/FuturaStdBookOblique.eot);
src:url(../fonts/futura-pt-alt/FuturaStdBookOblique.eot?#iefix) format("embedded-opentype"),
url(../fonts/futura-pt-alt/FuturaStdBookOblique.woff2) format("woff2"),
url(../fonts/futura-pt-alt/FuturaStdBookOblique.woff) format("woff"),
url(../fonts/futura-pt-alt/FuturaStdBookOblique.ttf) format("truetype");
font-display: swap;
}

@font-face{font-family:futura-pt-alt;font-weight:700;font-style:normal;
src:url(../fonts/futura-pt-alt/FuturaStdBold.eot);
src:url(../fonts/futura-pt-alt/FuturaStdBold.eot?#iefix) format("embedded-opentype"),
url(../fonts/futura-pt-alt/FuturaStdBold.woff2) format("woff2"),
url(../fonts/futura-pt-alt/FuturaStdBold.woff2) format("woff"),
url(../fonts/futura-pt-alt/FuturaStdBold.ttf) format("truetype");
font-display: swap;
}

@font-face{font-family:futura-pt-alt;font-weight:700;font-style:italic;
src:url(../fonts/futura-pt-alt/2F5C55_B_0.eot);
src:url(../fonts/futura-pt-alt/2F5C55_B_0.eot?#iefix) format("embedded-opentype"),
url(../fonts/futura-pt-alt/2F5C55_B_0.woff2) format("woff2"),
url(../fonts/futura-pt-alt/2F5C55_B_0.woff) format("woff"),
url(../fonts/futura-pt-alt/2F5C55_B_0.ttf) format("truetype");
font-display: swap;
}

/*
@include lib-font-face(
    $family-name: $font-family-name__base,
    $font-path: '../fonts/opensans/light/opensans-300',
    $font-weight: 300,
    $font-style: normal
);

@include lib-font-face(
    $family-name: $font-family-name__base,
    $font-path: '../fonts/opensans/regular/opensans-400',
    $font-weight: 400,
    $font-style: normal
);

@include lib-font-face(
    $family-name: $font-family-name__base,
    $font-path: '../fonts/opensans/semibold/opensans-600',
    $font-weight: 600,
    $font-style: normal
);

@include lib-font-face(
    $family-name: $font-family-name__base,
    $font-path: '../fonts/opensans/bold/opensans-700',
    $font-weight: 700,
    $font-style: normal
);*/

//
//  Desktop
//  _____________________________________________

@include min-screen($screen__m) {
    h1 {
        @include lib-css(font-size, $h1__font-size-desktop);
        @include lib-css(margin-bottom, $h1__margin-bottom__desktop);
    }
}

.items {
    @include lib-list-reset-styles();
}

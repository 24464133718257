@mixin __flip($container, $frontName, $backName) {
    #{$container} {
        -webkit-transform: rotateY(180deg);
        -moz-transform: rotateY(180deg);
        -o-transform: rotateY(180deg);
        transform: rotateY(180deg);			
    }

    #{$backName} {
        opacity: 1;
    }

    #{$frontName} {
        opacity: 0;
    }		
}

@mixin flip($container: '.flipper', $frontName: '.front', $backName: '.back', $useHover: true) {
    -webkit-perspective: 1000;
    -moz-perspective: 1000;
    -o-perspective: 1000;
    perspective: 1000;

    @if $useHover == true {
        &:hover {
            @include __flip($container, $frontName, $backName);
        }
    }

    &.hover {
        @include __flip($container, $frontName, $backName);
    }

    #{$container} {
        -webkit-transition: 0.6s;
        -webkit-transform-style: preserve-3d;
        -moz-transition: 0.6s;
        -moz-transform-style: preserve-3d;
        -o-transition: 0.6s;
        -o-transform-style: preserve-3d;
        transition: 0.6s;
        transform-style: preserve-3d;
        position: relative;
    }

    #{$frontName}, #{$backName} {

        -moz-backface-visibility: hidden;
        -o-backface-visibility: hidden;
        backface-visibility: visible;
        -webkit-backface-visibility: hidden;
        top: 0;
        left: 0;
        transition: 0.6s all;
    }

    #{$frontName} {
        opacity: 1;
    }

    #{$backName} {
        height: 100%;
        width: 100%;
        opacity: 0;
        z-index: 2;
        position: absolute;
        -webkit-transform: rotateY(180deg);
        -moz-transform: rotateY(180deg);
        -o-transform: rotateY(180deg);
        transform: rotateY(180deg);				
    }
}

//
//  Variables
//  _____________________________________________

$checkout-wrapper__margin              : $indent__base !default;
$checkout-wrapper__columns             : 8 !default;

$checkout-step-title__border           : $border-width__base solid $color-gray80 !default;
$checkout-step-title__font-size        : 26px !default;
$checkout-step-title__font-weight      : $font-weight__light !default;
$checkout-step-title__padding          : $indent__s !default;

$checkout-step-title-mobile__font-size : 18px !default;


.checkout-index-index {
    .page-title-wrapper {
        @extend .abs-visually-hidden;
    }

    .columns {
        .column.main {
            .opc-progress-bar {
                .opc-progress-bar-item {
                   & > span {
                    font-size: 1.7rem;
                    font-weight: 400;
                   }
                }
            }

            .opc-wrapper {
                .checkout-shipping-address,
                .checkout-billing-address {
                    .step-title,
                    .label > span,
                    .input-text,
                    select {
                        font-size: 1.6rem;
                    }

                    .step-title {
                        font-weight: 400;
                    }
                }

                .checkout-shipping-method {
                    font-size: 1.6rem;

                    .step-title {
                        font-size: 1.6rem;
                        font-weight: 400;
                    }

                    .actions-toolbar > button {
                        font-size: 1.6rem;
                    }
                }
            }

            .opc-sidebar {
                .opc-block-summary {
                    font-size: 1.6rem;

                    .title {
                        font-size: 1.6rem;
                        font-weight: 400;
                    }
                }

                .items-in-cart {
                    .title {
                        font-size: 1.6rem;
                    }
                }

                .minicart-items {
                    .product-item-details {
                        .product-item-name,
                        .details-qty {
                            font-size: 1.6rem;
                        }
                    }
                }
            }

            .checkout-payment-method {
                font-size: 1.6rem;

                .step-title {
                    font-size: 1.6rem;
                    font-weight: 400;
                }
            }

            .opc-block-shipping-information {
                font-size: 1.6rem;

                .shipping-information-title {
                    font-size: 1.6rem;
                }
            }
        }
    }
}

.boskalis-b2b.checkout-index-index {

        #shippingLocation {
            @include media-breakpoint-up(lg) {
                max-width: 500px;
                display: block;
                padding-left: 9px;
                padding-right: 9px;
            }
        }
        .opc-wrapper {
        .checkout-shipping-address {
            .field.addresses,                       
            .field[name$="telephone"],
            .field[name$="postcode"],
            .field[name$="country_id"],
            .field[name$="company"],
            .field.street,
            .field[name$="city"] {
                display: none !important;
            }

            .step-content {
                & > span,
                #shippingLocation {
                    font-size: 1.6rem;
                }
            }
        }
    }
}
.boskalis-b2p.checkout-index-index {

    #shippingLocation {
        @include media-breakpoint-up(lg) {
            max-width: 500px;
            display: block;
            padding-left: 9px;
            padding-right: 9px;
        }
    }
    .opc-wrapper {
    .checkout-shipping-address {
        .field.addresses,                       
        .field[name$="telephone"],
        .field[name$="postcode"],
        .field[name$="country_id"],
        .field[name$="company"],
        .field.street,
        .field[name$="city"] {
            display: none !important;
        }

        .step-content {
            & > span,
            #shippingLocation {
                font-size: 1.6rem;
            }
        }
    }
}
}

.boskalis-default {
        .opc-wrapper {
        .checkout-shipping-address {
        //     .field[name$="company"],            
        //     .field[name$="city"] {
        //         display: none !important;
        //     }
            .field[name$="street"] {
                label::after {
                    content: '*';
                    color: #e02b27;
                    font-size: 1.1rem;
                    margin: 0 0 0 5px;
                }
            }
            .field.street.admin__control-fields {
                legend.label{
                    display: none;
                }
                .control{
                    .field._required{
                        .label{
                            &::after {
                                content: '*';
                                color: #e02b27;
                                font-size: 1.1rem;
                                margin: 0 0 0 5px;
                            }
                        }
                    }
                }

            }
        }

        .checkout-billing-address {
            .field[name$="billingAddresscheckmo.company"],
            .field[name$="billingAddresstig_buckaroo_creditcard.company"],
            .field[name$="billingAddresstig_buckaroo_ideal.company"] {
                display: none !important;
            }
        }     
    }    
}

.checkout-container {
    @extend .abs-add-clearfix;
    @include lib-css(margin, 5rem 0 $checkout-wrapper__margin);
}

.opc-wrapper {
    @include lib-css(margin, 0 0 $checkout-wrapper__margin);

    .opc {
        @extend .abs-reset-list;
    }

    .step-title {
        @extend .abs-checkout-title;
    }

    .step-content {
        margin: 0 0 $indent__xl;
    }
}

/*.checkout-index-index {
    .nav-sections,
    .nav-toggle {
        display: none;
    }

    .logo {
        margin-left: 0;
    }
}*/

.checkout-onepage-success {
    @extend .abs-add-clearfix;

    .print {
        display: none;
    }
}

.b2p {
    .checkout-shipping-address {
        .step-title {
            &:after {
                content: "Please note shipping costs are free for delivery at Boskalis Papendrecht. \A \A Delivery at a Boskalis office abroad? Please select delivery Boskalis Papendrecht and we will distribute it to your office. Please take a maximum shipping time of 3 weeks into account.";
                font-weight: bold;     
                display: block;     
                margin-top: 10px;  
                white-space: pre-wrap;
            }
        }
    }
}

//
//  Mobile
//  _____________________________________________

@include max-screen($screen__s) {
    .opc-wrapper {
        .step-title {
            @include lib-css(font-size, $checkout-step-title-mobile__font-size);
            border-bottom: 0;
            padding-bottom: 0;
        }
        .form.form-login{
            .fieldset{
                .note{
                    display:none;
                }
            }
        }
    }
}

//
//  Desktop
//  _____________________________________________

@include min-screen($screen__m) {
    .opc-wrapper {
        @extend .abs-add-box-sizing-desktop-m;
        @include lib-layout-column(2, 1, $checkout-wrapper__columns);
        padding-right: $indent__l;
    }

    .checkout-onepage-success {
        .print {
            display: block;
            float: right;
            margin: 22px 0 0;
        }
    }
}

.opc-estimated-wrapper{
    .minicart-wrapper{
        .action{
            .showcart::before{
                @include media-breakpoint-down(sm){
                    color: white;
                }
            } 
        } 
    } 
}

.checkout-payment-method .step-title{
    @include media-breakpoint-down(sm){
        padding-left: 3rem;
    }
}

.minicart-wrapper::before{
    @include media-breakpoint-down(sm){
        background: #fff;
    }
}

.minicart-wrapper{
    .action.showcart{
        .counter.qty{
            @include media-breakpoint-down(sm){
                float: right;
                margin-left: 1rem;
                margin-top: 8px;
                line-height: 1.5;
            }
        } 
    } 
}

.quickbuy-modal{
    .modal-inner-wrap{
        max-width: 480px;
    }
}

.quickbuy-modal{
    .modal-inner-wrap{
        .quantity{
            max-width: 70px;
        }
    }
}

.quickbuy-modal{
    .modal-inner-wrap{
        .quantity{
            .label{
                display: none;
            }

        }
    }
}

#product_addtocart_form{
    .price-box{
        margin-bottom: 2rem;
    }
}

#product_addtocart_form{
    .stock-indication{
        margin-bottom: 2rem;
    }
}

.box-tocart{
    .fieldset{
        #product-addtocart-button{
            padding: 0.83rem;
        }
    }
}

.actions-toolbar{
    .secondary{
        .action{
            span{
                margin-right: 2.6rem;
            }
        }
    }
}
.field-recaptcha{
    .field{
        display: none;
    }
}
.actions-toolbar{
    .secondary{
        .action{
            @include media-breakpoint-down(sm){
                margin-right: 30rem;
            }
        }
    }
}

.tig_buckaroo_ideal{
    .tig_buckaroo_image_title::after {
        position: relative;
        margin-left: 1rem;
        left: 0;
    }
}


.tig_buckaroo_creditcard{
    .tig_buckaroo_image_title::after {
        position: relative;
        margin-left: 1rem;
        left: 0;
    }
}

.order-details-items .order-title > strong{
    display: none;
}

.price-box{
    .price-label{
        display: none;
    }
}


.page-product-bundle {
    .page-main {
        .columns {
            .column.main {
                .product-info-main {
                    .bundle-actions {
                        .action.primary.customize {
                            margin-bottom: 10px;
                        }
                    }
                }

                .bundle-options-container {
                    .block-bundle-summary {
                        background-color: #fff;

                        .title {
                            display: none;
                        }

                        .content {
                            .bundle-info {
                                display: flex;
                                flex-direction: row;
                                position: relative;
                                flex-wrap: wrap;

                                .product-image-container {
                                    margin-right: 20px;
                                }

                                .product.name {
                                    font-size: 28px;
                                    font-weight: normal;
                                }

                                .product-details {
                                    display: flex;
                                    flex-direction: column;
                                    width: 100%;

                                    .price-box {
                                        position: absolute;
                                        top: 40px;
                                        left: 160px;

                                        margin: 0;
                                        margin-top: 20px;

                                        p {
                                            margin: 0;
                                        }
                                    }

                                    .bundle-options-wrapper {
                                        width: 150%;
                                        margin-top: 20px;

                                        .product-options-wrapper {
                                            margin-bottom: 0;

                                            .price {
                                                font-size: 14px;
                                            }

                                            .fieldset-bundle-options {
                                                margin-bottom: 0px;
                                                .option{
                                                    .control{
                                                        .price-notice{
                                                            display: none;
                                                        }
                                                    }
                                                }
                                                .field.qty {
                                                    display: none;
                                                }

                                                .field.choice {
                                                    display: flex;

                                                    input {
                                                        margin-right: 5px;
                                                    }
                                                }
                                            }
                                        }
                                    }

                                    .box-tocart {

                                        .fieldset {
                                            display: flex;
                                            flex-direction: column;
                                            align-items: flex-start;

                                            .field.qty {

                                                .control {
                                                   display: flex;
                                                   flex-direction: column;
                                               }
                                            }
    
                                            .actions {
                                                .action.primary.tocart {
                                                    margin: 0;
                                                }

                                                .action.primary.checkout {
                                                    display: none;
                                                }
                                            }

                                            div.mage-error[generated] {
                                                position: absolute;
                                                top: 100%;
                                                margin-top: 0;
                                            }
                                        }
                                    }

                                    .available.stock {
                                        // margin: 0;
                                        display: none;
                                    }
                                }
                            }

                            .bundle-summary {
                                display: none;
                            }
                        }
                    }
                }
            }
        }
    }
   
}

.product-item-details{
    .options{
        .values{
            .price{
                display: none;
            }
        }
    }
    .item-options{
        dd{
            .price{
                display: none;
            }
        }
    }
}